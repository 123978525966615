/** @jsxImportSource @emotion/react */
import { DeleteOutlined } from '@ant-design/icons'
import {
  Button,
  notification,
  Pagination,
  Popconfirm,
  Table,
  DatePicker,
  Space,
  Input,
} from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { ColumnType } from 'antd/es/table'
import {
  FilterConfirmProps,
  FilterValue,
  TablePaginationConfig,
} from 'antd/es/table/interface'
import { useCallback, useEffect, useState } from 'react'
import {
  useAllOrganizationsQuery,
  useDeleteOrganizationMutation,
} from 'services/auth'

import dayjs from 'dayjs'
import CreateNewOrganization from './CreateNewOrganization'
import { OrganizationModel } from 'models'

export default function Resources() {
  const [openCreateModal, setOpenCreateModal] = useState(false)
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState({
    name: '',
    description: '',
    created_at: '',
  })
  const [orderBy, setOrderBy] = useState<string>()
  const [orderOption, setOrderOption] = useState<string>('desc')

  const { data, isLoading, isFetching, refetch } = useAllOrganizationsQuery({
    page,
    size: 10,
    order_by: orderBy || undefined,
    order_option: orderOption || undefined,
    name: search.name || undefined,
    description: search.description || undefined,
    start_date: search.created_at ? search.created_at.split(',')[0] : undefined,
    end_date: search.created_at ? search.created_at.split(',')[1] : undefined,
  })

  const [
    deleteOrganization,
    { isLoading: isDeleting, data: deleteOrganizationResponse },
  ] = useDeleteOrganizationMutation()

  const handleDelete = (id: string) => {
    return deleteOrganization(id)
  }

  const handleReset = (clearFilters: () => void) => {
    clearFilters()
    setSearch({
      description: '',
      name: '',
      created_at: '',
    })
  }

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: string
  ) => {
    confirm()
    setSearch((prev) => ({
      ...prev,
      [dataIndex]: selectedKeys[0] ? selectedKeys[0].trim() : '',
    }))
  }

  const getColumnSearchProps = useCallback(
    (dataIndex: string): ColumnType<OrganizationModel> => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        close,
      }) => {
        return (
          <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
            {dataIndex === 'created_at' ? (
              <DatePicker.RangePicker
                css={{ marginBottom: 8, display: 'flex' }}
                value={
                  selectedKeys[0]?.toString().split(',')[0].length === 2
                    ? [
                        dayjs(selectedKeys[0]?.toString().split(',')[0]),
                        dayjs(selectedKeys[0]?.toString().split(',')[1]),
                      ]
                    : undefined
                }
                onChange={(values) => {
                  if (values?.every((item) => !!item))
                    setSelectedKeys([
                      values
                        .map((item) => item?.format('YYYY-MM-DD'))
                        .join(','),
                    ])
                }}
              />
            ) : (
              <Input
                autoFocus
                // ref={searchInput}
                placeholder={`Search ${dataIndex}`}
                value={selectedKeys[0]}
                allowClear
                onChange={(e) =>
                  setSelectedKeys(e.target.value ? [e.target.value] : [])
                }
                onPressEnter={() =>
                  handleSearch(selectedKeys as string[], confirm, dataIndex)
                }
                css={{ marginBottom: 8, display: 'flex' }}
              />
            )}

            <Space>
              <Button
                type="primary"
                onClick={() =>
                  handleSearch(selectedKeys as string[], confirm, dataIndex)
                }
                icon={<SearchOutlined />}
                size="small"
                css={{ width: 90 }}
              >
                Search
              </Button>
              <Button
                onClick={() => {
                  clearFilters && handleReset(clearFilters)
                  confirm({ closeDropdown: true })
                  setSearch({
                    name: '',
                    description: '',
                    created_at: '',
                  })
                }}
                size="small"
                css={{ width: 90 }}
              >
                Reset
              </Button>
              <Button
                type="link"
                size="small"
                onClick={() => {
                  close()
                }}
              >
                close
              </Button>
            </Space>
          </div>
        )
      },

      filterIcon: (filtered: boolean) => (
        <SearchOutlined
          style={{
            color: filtered ? '#1890ff' : undefined,
            padding: '0 0.75rem',
          }}
        />
      ),
    }),
    []
  )

  const handleTableChange = (
    _pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: any
  ) => {
    setOrderBy(sorter.field)
    setOrderOption(sorter.order === 'ascend' ? 'asc' : 'desc')
  }

  useEffect(() => {
    if (deleteOrganizationResponse && deleteOrganizationResponse.data) {
      refetch()
      notification.success({
        message: 'Success',
        description: `Successfully deleted organization`,
      })
    }
  }, [deleteOrganizationResponse, refetch])

  const onCancel = useCallback(
    (withRefresh: boolean) => {
      setOpenCreateModal(false)
      if (withRefresh) refetch()
    },
    [refetch]
  )

  return (
    <div css={{ padding: '1rem' }}>
      {openCreateModal && (
        <CreateNewOrganization open={openCreateModal} onClose={onCancel} />
      )}
      <div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '1.5rem',
        }}
      >
        <div css={{ fontSize: '1.25rem', fontWeight: '500' }}>
          Organization Management
        </div>
        <Button type="primary" onClick={() => setOpenCreateModal(true)}>
          Add new organization
        </Button>
      </div>
      <Table
        loading={isLoading || isFetching}
        dataSource={data?.data}
        css={{ marginTop: '1rem' }}
        rowKey="id"
        onChange={handleTableChange}
        columns={[
          {
            title: 'Name',
            dataIndex: 'name',
            ...getColumnSearchProps('name'),
            sorter: true,
          },
          {
            title: 'Description',
            dataIndex: 'description',
            ...getColumnSearchProps('description'),
            sorter: true,
          },
          {
            title: 'Logo',
            dataIndex: 'logo',
          },
          {
            title: 'Created at',
            dataIndex: 'created_at',
            sorter: true,
            ...getColumnSearchProps('created_at'),
            render: (created_at) =>
              dayjs(created_at).format('DD-MM-YYYY HH:mm'),
          },
          {
            title: 'Action',
            align: 'right',
            render: (_text, record) => (
              <Popconfirm
                onConfirm={() => handleDelete(record.id)}
                title="Delete organization"
                description={() => (
                  <div>
                    Are you sure to delete this organization{' '}
                    <b>{record.name}</b>?
                  </div>
                )}
                okType="danger"
                okButtonProps={{ loading: isDeleting }}
              >
                <Button danger size="small" icon={<DeleteOutlined />}>
                  Delete
                </Button>
              </Popconfirm>
            ),
          },
        ]}
        pagination={false}
      />

      <Pagination
        css={{ marginTop: '1rem', textAlign: 'right' }}
        pageSize={10}
        current={page}
        onChange={(page) => setPage(page)}
        total={data?.pagination.total}
        showTotal={(total) => `Total ${total} groups`}
      />
    </div>
  )
}
