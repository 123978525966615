/** @jsxImportSource @emotion/react */
import { SiderProps } from 'antd/lib/layout'
import { Layout, Menu } from 'antd'
import { Link, useLocation } from 'react-router-dom'
import routes from 'routes'
import SubMenu from 'antd/lib/menu/SubMenu'
import { ReactComponent as DatapalLogo } from 'assets/logo-full.svg'
import { ReactComponent as DatapalLogoLite } from 'assets/logo-lite.svg'
import { theme } from 'theme'

const { Sider } = Layout

interface SidebarProps extends SiderProps {
  isMobile?: boolean
}

function Sidebar(props: SidebarProps) {
  const { isMobile, collapsed, onCollapse } = props
  const { pathname } = useLocation()

  const navigationsMap: { [key: string]: any } = {}

  // add all parent node to navigationsMap
  routes.forEach((menu) => {
    if (!menu.menuParentId) {
      navigationsMap[menu.id] = { ...menu }
    }
  })

  // add children to node
  routes.forEach((menu) => {
    if (menu.menuParentId && menu.menuParentId !== '-1') {
      const parent = navigationsMap[menu.menuParentId]
      if (parent) {
        !parent.children && (parent.children = [])
        parent.children.push(menu)
      }
    }
  })

  const navigations = Object.values(navigationsMap)

  const selectedNav = routes.find((item) => item.path === pathname)

  return (
    <Sider
      width={256}
      collapsible
      trigger={null}
      breakpoint="xl"
      collapsed={collapsed}
      onCollapse={onCollapse}
    >
      <div css={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
        <div
          css={{ display: 'flex', padding: '1rem', justifyContent: 'center' }}
        >
          {collapsed ? <DatapalLogoLite /> : <DatapalLogo />}
        </div>

        <div
          css={{
            flex: 1,
            padding: '1.5rem 0',
            overflowY: 'auto',
            overflowX: 'hidden',
          }}
          className="app-sider-menu"
        >
          <Menu
            theme="dark"
            mode="inline"
            selectedKeys={selectedNav ? [selectedNav.id] : undefined}
            onClick={() =>
              onCollapse &&
              isMobile &&
              onCollapse(props.collapsed ? false : true, 'clickTrigger')
            }
            defaultOpenKeys={
              selectedNav && selectedNav.menuParentId
                ? [selectedNav.menuParentId]
                : undefined
            }
          >
            {navigations
              .filter((nav) => !nav.isHidden)
              .map((nav) => {
                const { children } = nav

                // parent navigation
                if (!children) {
                  return (
                    <Menu.Item key={nav.id} icon={<nav.icon />}>
                      <Link to={nav.path}>{nav.name}</Link>
                    </Menu.Item>
                  )
                }

                // sub navigation
                return (
                  <SubMenu key={nav.id} icon={<nav.icon />} title={nav.name}>
                    {children.map((sub: any) => (
                      <Menu.Item key={sub.id} icon={<sub.icon />}>
                        <Link to={sub.path}>{sub.name}</Link>
                      </Menu.Item>
                    ))}
                  </SubMenu>
                )
              })}
          </Menu>
        </div>

        {!collapsed && (
          <div
            css={{
              color: theme.subText,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '4rem',
            }}
          >
            Datapal Team @ 2022
          </div>
        )}
      </div>
    </Sider>
  )
}

export default Sidebar
