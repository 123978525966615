import { Form, Modal, ModalProps, Select, notification } from 'antd'
import { AddUserGroupModel, UserModel } from 'models'
import { useEffect, useState } from 'react'
import { useUsersQuery } from 'services/auth'
import {
  Group,
  useAllGroupsQuery,
  useCreateUserGroupMutation,
} from 'services/authz'

interface Props extends ModalProps {
  onClose: (withRefresh: boolean) => void
  onSuccess: () => void
  selectedUser: UserModel
}
export default function AddUserToGroup({
  onClose,
  selectedUser,
  ...props
}: Props) {
  const [form] = Form.useForm<AddUserGroupModel>()

  const [openDisclaimModal, setOpenDisclaimModal] = useState<boolean>(false)

  const { data: groupList } = useAllGroupsQuery({
    page: 1,
    size: 100,
  })

  const { data: userList } = useUsersQuery({
    page: 1,
    size: 100,
  })

  const [createUserGroup, { isLoading, data, error }] =
    useCreateUserGroupMutation()

  useEffect(() => {
    if (data?.data) {
      notification.success({
        message: 'Success',
        description: `Successfully added user to group`,
      })
      onClose(true)
    }
  }, [data, onClose])

  useEffect(() => {
    if (error) {
      notification.error({
        message: 'Error',
        description: (error as any)?.data?.errors?.detail,
      })
    }
  }, [error])

  const onFinish = ({ group_id, user_id }: AddUserGroupModel) => {
    createUserGroup({
      group_id,
      user_id,
    })
  }

  const handleFormValuesChange = (changedValues: AddUserGroupModel) => {
    const formFieldName = Object.keys(changedValues)[0]
    const selectedGroup = changedValues.group_id.split('_')

    if (
      formFieldName === 'group_id' &&
      selectedGroup[0] !== selectedUser.organization
    ) {
      setOpenDisclaimModal(true)
    }
  }

  const handleDismiss = () => {
    form.setFieldValue('group_id', '')
    setOpenDisclaimModal(false)
  }

  return (
    <Modal
      title="Create new user group"
      centered
      okText="Create"
      onCancel={() => onClose(false)}
      okButtonProps={{ htmlType: 'submit', loading: isLoading }}
      onOk={form.submit}
      {...props}
    >
      {openDisclaimModal && (
        <Modal
          title="Switch organization"
          centered
          okText="Yes"
          onCancel={handleDismiss}
          okButtonProps={{ htmlType: 'submit' }}
          onOk={() => setOpenDisclaimModal(false)}
          {...props}
        >
          You are switching organization, please click Yes to confirm your
          action
        </Modal>
      )}
      <Form
        layout="vertical"
        onFinish={onFinish}
        form={form}
        onValuesChange={handleFormValuesChange}
        initialValues={{ user_id: selectedUser.user_id }}
      >
        <Form.Item
          name="user_id"
          label="User"
          rules={[{ required: true, message: 'Please select user' }]}
        >
          <Select
            placeholder="Select user"
            disabled
            options={userList?.data?.map((user: UserModel) => ({
              value: user.id,
              label: user.name,
            }))}
          />
        </Form.Item>
        <Form.Item
          name="group_id"
          label="Group"
          rules={[{ required: true, message: 'Please select group' }]}
        >
          <Select
            placeholder="Select group..."
            options={groupList?.data?.map((group: Group) => ({
              value: group.id,
              label: group.name,
            }))}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}
