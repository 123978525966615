/** @jsxImportSource @emotion/react */
import { DeleteOutlined } from '@ant-design/icons'
import { Button, notification, Pagination, Popconfirm, Table } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { useAllResourcesQuery, useDeleteResourceMutation } from 'services/authz'
import CreateNewGroup from './CreateResource'
import dayjs from 'dayjs'

export default function Resources() {
  const [openCreateModal, setOpenCreateModal] = useState(false)
  const [page, setPage] = useState(1)

  const { data, isLoading, isFetching, refetch } = useAllResourcesQuery({
    page,
    size: 10,
  })
  const [
    deleteResource,
    { isLoading: isDeleting, data: deleteResourceResponse },
  ] = useDeleteResourceMutation()

  const handleDelete = (groupId: string) => {
    return deleteResource(groupId)
  }
  useEffect(() => {
    if (deleteResourceResponse && deleteResourceResponse.errors === null) {
      refetch()
      notification.success({
        message: 'Success',
        description: `Successfully deleted resource`,
      })
    }
  }, [deleteResourceResponse, refetch])

  const onCancel = useCallback(
    (withRefresh: boolean) => {
      setOpenCreateModal(false)
      if (withRefresh) refetch()
    },
    [refetch]
  )

  return (
    <div css={{ padding: '1rem' }}>
      <CreateNewGroup open={openCreateModal} onClose={onCancel} />
      <div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '1.5rem',
        }}
      >
        <div css={{ fontSize: '1.25rem', fontWeight: '500' }}>
          Resource Managements
        </div>
        <Button type="primary" onClick={() => setOpenCreateModal(true)}>
          Add new resource
        </Button>
      </div>
      <Table
        loading={isLoading || isFetching}
        dataSource={data?.data}
        css={{ marginTop: '1rem' }}
        columns={[
          {
            title: 'ID',
            dataIndex: 'id',
          },
          {
            title: 'Name',
            dataIndex: 'name',
          },
          {
            title: 'Path',
            dataIndex: 'path',
          },
          {
            title: 'Service',
            dataIndex: 'service_id',
          },
          {
            title: 'Type',
            dataIndex: 'type',
          },
          {
            title: 'Deleted',
            dataIndex: 'deleted',
          },
          {
            title: 'Created at',
            dataIndex: 'created_at',
            render: (text) => dayjs(text).format('DD-MM-YYYY HH:mm'),
          },
          {
            title: 'Updated at',
            dataIndex: 'updated_at',
            render: (text) => dayjs(text).format('DD-MM-YYYY HH:mm'),
          },
          {
            title: 'Action',
            align: 'right',
            render: (_text, record) => (
              <Popconfirm
                onConfirm={() => handleDelete(record.id)}
                title="Delete resource"
                description={`Are you sure to delete this resource`}
                okButtonProps={{ loading: isDeleting }}
              >
                <Button danger size="small" icon={<DeleteOutlined />}>
                  Delete
                </Button>
              </Popconfirm>
            ),
          },
        ]}
        pagination={false}
      />

      <Pagination
        css={{ marginTop: '1rem', textAlign: 'right' }}
        pageSize={10}
        current={page}
        onChange={(page) => setPage(page)}
        total={data?.pagination.total}
        showTotal={(total) => `Total ${total} groups`}
      />
    </div>
  )
}
