/** @jsxImportSource @emotion/react */
import { DeleteOutlined } from '@ant-design/icons'
import {
  Button,
  Input,
  notification,
  Popconfirm,
  Table,
  Tag,
  DatePicker,
  Space,
} from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { ColumnType, TablePaginationConfig } from 'antd/es/table'
import { FilterConfirmProps, FilterValue } from 'antd/es/table/interface'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDeleteUserMutation, useUsersQuery } from 'services/auth'
import { useGetUserRoleMutation } from 'services/authz'
import dayjs from 'dayjs'
import CreateNewUser from './CreateNewUser'
import AddUserToGroup from './AddUserToGroup'
import { UserModel } from 'models'

interface DataType {
  key: string
  name: string
  email: string
}

interface DataType {
  key: string
  name: string
  email: string
}

function UserManagements() {
  const [allUserPage, setAllUserPage] = useState(1)
  const [search, setSearch] = useState({
    name: '',
    email: '',
    created_time: '',
  })
  const [openCreateModal, setOpenCreateModal] = useState(false)
  const [openAddUserGroupModal, setOpenAddUserGroupModal] = useState(false)
  const [selectedUser, setSelectedUser] = useState<UserModel | null>()

  const [orderBy, setOrderBy] = useState<string>()
  const [orderOption, setOrderOption] = useState<string>()

  const [statusFilter, setStatusFilter] = useState<string>('')

  const { data, isLoading, isFetching, refetch } = useUsersQuery({
    page: allUserPage,
    limit: 10,
    order_by: orderBy || undefined,
    order_option: orderOption || undefined,
    status: statusFilter || undefined,
    name: search.name || undefined,
    email: search.email || undefined,
    start_date: search.created_time
      ? search.created_time.split(',')[0]
      : undefined,
    end_date: search.created_time
      ? search.created_time.split(',')[1]
      : undefined,
  })

  const [usersRole, setUsersRole] = useState<
    { email: string; roles: string[] }[]
  >([])

  enum ROLE_ENUM {
    'datapal_admin' = 'Admin',
    'datapal_user' = 'User',
    'true_media_admin' = 'True Media Admin',
  }

  const handleReset = (clearFilters: () => void) => {
    clearFilters()
    setSearch({
      email: '',
      name: '',
      created_time: '',
    })
  }

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: string
  ) => {
    confirm()
    setSearch((prev) => ({
      ...prev,
      [dataIndex]: selectedKeys[0] ? selectedKeys[0].trim() : '',
    }))
  }

  const getColumnSearchProps = useCallback(
    (dataIndex: string): ColumnType<DataType> => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        close,
      }) => {
        return (
          <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
            {dataIndex === 'created_time' ? (
              <DatePicker.RangePicker
                css={{ marginBottom: 8, display: 'flex' }}
                value={
                  selectedKeys[0]?.toString().split(',')[0].length === 2
                    ? [
                        dayjs(selectedKeys[0]?.toString().split(',')[0]),
                        dayjs(selectedKeys[0]?.toString().split(',')[1]),
                      ]
                    : undefined
                }
                onChange={(values) => {
                  if (values?.every((item) => !!item))
                    setSelectedKeys([
                      values
                        .map((item) => item?.format('YYYY-MM-DD'))
                        .join(','),
                    ])
                }}
              />
            ) : (
              <Input
                autoFocus
                // ref={searchInput}
                placeholder={`Search ${dataIndex}`}
                value={selectedKeys[0]}
                allowClear
                onChange={(e) =>
                  setSelectedKeys(e.target.value ? [e.target.value] : [])
                }
                onPressEnter={() =>
                  handleSearch(selectedKeys as string[], confirm, dataIndex)
                }
                css={{ marginBottom: 8, display: 'flex' }}
              />
            )}

            <Space>
              <Button
                type="primary"
                onClick={() =>
                  handleSearch(selectedKeys as string[], confirm, dataIndex)
                }
                icon={<SearchOutlined />}
                size="small"
                css={{ width: 90 }}
              >
                Search
              </Button>
              <Button
                onClick={() => {
                  clearFilters && handleReset(clearFilters)
                  confirm({ closeDropdown: true })
                  setSearch({
                    name: '',
                    email: '',
                    created_time: '',
                  })
                }}
                size="small"
                css={{ width: 90 }}
              >
                Reset
              </Button>
              <Button
                type="link"
                size="small"
                onClick={() => {
                  close()
                }}
              >
                close
              </Button>
            </Space>
          </div>
        )
      },

      filterIcon: (filtered: boolean) => (
        <SearchOutlined
          style={{
            color: filtered ? '#1890ff' : undefined,
            padding: '0 0.75rem',
          }}
        />
      ),
    }),
    []
  )

  const [
    deleteUser,
    { isLoading: isDeleting, data: deleteUserResponse, error: deleteUserError },
  ] = useDeleteUserMutation()

  const handleDeleteUser = useCallback(
    async (email: string) => {
      return deleteUser(email)
    },
    [deleteUser]
  )

  const handleAddUserToGroup = (user: UserModel) => {
    setOpenAddUserGroupModal(true)
    setSelectedUser(user)
  }

  const columns = useMemo(() => {
    return [
      {
        title: 'Name',
        dataIndex: 'name',
        ...getColumnSearchProps('name'),
        sorter: true,
      },
      {
        title: 'Email',
        dataIndex: 'email',
        ...getColumnSearchProps('email'),
        sorter: true,
      },
      {
        title: 'Role',
        dataIndex: 'role',
        sorter: false,
        render: (_value: string, record: any) => {
          const foundUser = usersRole?.find(
            (item) => item.email === record.email
          )

          let displayRoles: string[] = []
          if (foundUser?.roles && foundUser?.roles.length > 0) {
            displayRoles = foundUser.roles.map(
              (role) => ROLE_ENUM[role as keyof typeof ROLE_ENUM] || role
            )
          }
          return <span>{displayRoles.join(', ')}</span>
        },
      },
      {
        dataIndex: 'status',
        title: 'Status',
        filters: [
          {
            text: 'Active',
            value: 'Active',
          },
          {
            text: 'Pending',
            value: 'Pending',
          },
        ],
        sorter: false,
        filterMultiple: false,
        render: (value: string, _record: any) => (
          <Tag color={value === 'Active' ? 'green' : 'orange'}>{value}</Tag>
        ),
      },
      {
        title: 'Created at',
        dataIndex: 'created_time',
        width: '15%',
        render: (value: string) => (
          <p>{dayjs(value).format('MM/DD/YYYY HH:mm:ss')}</p>
        ),
        sorter: true,
        ...getColumnSearchProps('created_time'),
      },
      {
        title: 'Action',
        dataIndex: 'action',
        sorter: false,
        width: '20%',
        render: (_text: string, record: any) => (
          <>
            <Popconfirm
              onConfirm={() => handleDeleteUser(record.email)}
              title="Delete user"
              okType="danger"
              description={() => (
                <div>
                  Are you sure to delete this user <b>{record.full_name}</b>?
                </div>
              )}
              okButtonProps={{ loading: isDeleting }}
            >
              <Button danger size="small" icon={<DeleteOutlined />}>
                Delete
              </Button>
            </Popconfirm>

            <Button
              size="small"
              type="primary"
              style={{ marginLeft: '10px' }}
              onClick={() => handleAddUserToGroup(record)}
            >
              Add to group
            </Button>
          </>
        ),
      },
    ]
  }, [ROLE_ENUM, getColumnSearchProps, handleDeleteUser, isDeleting, usersRole])

  const [getRole] = useGetUserRoleMutation()

  const getRoles = useCallback(async () => {
    const promises = []
    for (let i = 0; i < data?.data.length; i += 1) {
      promises.push(getRole(data?.data[i].email))
    }

    const res = await Promise.all(promises)
    const tmpRoles: { email: string; roles: string[] }[] = []

    res.map((dataRole: any, idx) => {
      if (dataRole?.data) {
        tmpRoles.push({
          email: data?.data[idx].email,
          roles: dataRole?.data?.data,
        })
      }
      return null
    })
    setUsersRole(tmpRoles)
  }, [data, getRole])

  useEffect(() => {
    if (deleteUserResponse) {
      if (deleteUserResponse && deleteUserResponse.data) {
        refetch()
        notification.success({
          message: 'Success',
          description: `Successfully deleted user`,
        })
      }
    }
  }, [deleteUserResponse, refetch])

  useEffect(() => {
    if (deleteUserError) {
      notification.error({
        message: 'Error',
        description: (deleteUserError as any)?.data?.errors?.[0]?.message,
      })
    }
  }, [deleteUserError])

  useEffect(() => {
    getRoles()
  }, [getRoles])

  const handleTableChange = (
    _pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: any
  ) => {
    // @ts-ignore: Unreachable code error
    setStatusFilter(filters?.status ? filters?.status[0] : undefined)
    setOrderBy(sorter.field)
    setOrderOption(sorter.order === 'ascend' ? 'asc' : 'desc')
  }

  const onCancel = useCallback(
    (withRefresh: boolean) => {
      setOpenCreateModal(false)
      if (withRefresh) refetch()
    },
    [refetch]
  )
  const onCancelAddGroup = useCallback(
    (withRefresh: boolean) => {
      setOpenAddUserGroupModal(false)
      if (withRefresh) {
        refetch()
        getRoles()
      }
    },
    [refetch, getRoles]
  )

  return (
    <>
      {openCreateModal && (
        <CreateNewUser
          open={openCreateModal}
          onClose={onCancel}
          onSuccess={refetch}
        />
      )}

      {openAddUserGroupModal && selectedUser && (
        <AddUserToGroup
          open={openAddUserGroupModal}
          onClose={onCancelAddGroup}
          onSuccess={refetch}
          selectedUser={selectedUser}
        />
      )}
      <Table
        loading={isLoading || isFetching}
        css={{ marginTop: '1rem' }}
        rowKey="id"
        dataSource={data?.data || []}
        title={() => (
          <div
            css={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div css={{ fontWeight: '500', fontSize: '16px' }}>
              User Management
            </div>
            <Button type="primary" onClick={() => setOpenCreateModal(true)}>
              Invite user
            </Button>
          </div>
        )}
        columns={columns}
        onChange={handleTableChange}
        pagination={{
          pageSize: 10,
          total: data?.pagination?.total || 0,
          current: allUserPage,
          onChange: (page) => setAllUserPage(page),
        }}
      ></Table>
    </>
  )
}

export default UserManagements
