import { Form, Input, Modal, ModalProps, notification } from 'antd'
import { useEffect } from 'react'
import { useCreateGroupMutation } from 'services/authz'

interface Props extends ModalProps {
  onClose: (withRefresh: boolean) => void
}
export default function CreateNewGroup({ onClose, ...props }: Props) {
  const [form] = Form.useForm<{ name: string; description: string }>()

  const [createGroup, { isLoading, data, error }] = useCreateGroupMutation()

  useEffect(() => {
    if (!data?.errors && data?.data.name) {
      notification.success({
        message: 'Success',
        description: `Successfully created group ${data?.data.name}`,
      })
      onClose(true)
    }
  }, [data, onClose])

  useEffect(() => {
    if (error) {
      notification.error({
        message: 'Error',
        description: (error as any)?.data?.errors?.detail,
      })
    }
  }, [error])

  const onFinish = ({
    name,
    description,
  }: {
    name: string
    description: string
  }) => {
    createGroup({ name, description })
  }
  return (
    <Modal
      title="Create new group"
      centered
      okText="Create"
      onCancel={() => onClose(false)}
      okButtonProps={{ htmlType: 'submit', loading: isLoading }}
      onOk={form.submit}
      {...props}
    >
      <Form layout="vertical" onFinish={onFinish} form={form}>
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: 'Please input group name' }]}
        >
          <Input placeholder="Group name..." />
        </Form.Item>
        <Form.Item
          name="description"
          label="Description"
          rules={[
            { required: true, message: 'Please input group description' },
          ]}
        >
          <Input placeholder="Group description..." />
        </Form.Item>
      </Form>
    </Modal>
  )
}
