import { Form, Modal, ModalProps, Select, notification } from 'antd'
import { AddGroupPolicyModel } from 'models'
import { useEffect } from 'react'
import {
  Group,
  Policy,
  useAllGroupsQuery,
  useAllPoliciesQuery,
  useCreateGroupPoliciesMutation,
} from 'services/authz'

interface Props extends ModalProps {
  onClose: (withRefresh: boolean) => void
  onSuccess: () => void
}
export default function CreateNewGroupPolicy({
  onClose,
  onSuccess,
  ...props
}: Props) {
  const [form] = Form.useForm<AddGroupPolicyModel>()

  const { data: groupList } = useAllGroupsQuery({
    page: 1,
    size: 100,
  })

  const { data: policyList } = useAllPoliciesQuery({
    page: 1,
    size: 100,
  })

  const [createGroupPolicy, { isLoading, data, error }] =
    useCreateGroupPoliciesMutation()

  useEffect(() => {
    if (data?.data) {
      notification.success({
        message: 'Success',
        description: `Successfully created group polices`,
      })
      onClose(true)
      onSuccess()
    }
  }, [data, onClose, onSuccess])

  useEffect(() => {
    if (error) {
      notification.error({
        message: 'Error',
        description: (error as any)?.data?.errors?.detail,
      })
    }
  }, [error])

  const onFinish = ({ group_id, policy_list }: AddGroupPolicyModel) => {
    createGroupPolicy({
      group_id,
      policy_list,
    })
  }

  return (
    <Modal
      title="Create new group polices"
      centered
      okText="Create"
      onCancel={() => onClose(false)}
      okButtonProps={{ htmlType: 'submit', loading: isLoading }}
      onOk={form.submit}
      {...props}
    >
      <Form layout="vertical" onFinish={onFinish} form={form}>
        <Form.Item
          name="group_id"
          label="Group"
          rules={[{ required: true, message: 'Please select group' }]}
        >
          <Select
            placeholder="Select group..."
            options={groupList?.data?.map((group: Group) => ({
              value: group.id,
              label: group.name,
            }))}
          />
        </Form.Item>

        <Form.Item
          name="policy_list"
          label="Polices"
          rules={[{ required: true, message: 'Please select policies' }]}
        >
          <Select
            placeholder="Select polices..."
            mode="multiple"
            options={policyList?.data?.map((policy: Policy) => ({
              value: policy.id,
              label: policy.name,
            }))}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}
