/** @jsxImportSource @emotion/react */
import { DeleteOutlined } from '@ant-design/icons'
import { Button, notification, Pagination, Popconfirm, Table, Tag } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { useAllActionsQuery, useDeleteActionMutation } from 'services/authz'
import CreateAction from './CreateAction'

const getMethodColor = (tag: string) => {
  switch (tag) {
    case 'GET':
      return 'green'
    case 'PUT':
      return 'blue'
    case 'POST':
      return 'yellow'
    case 'DELETE':
      return 'red'
    default:
      return undefined
  }
}
export default function Actions() {
  const [openCreateModal, setOpenCreateModal] = useState(false)
  const [page, setPage] = useState(1)

  const { data, isLoading, isFetching, refetch } = useAllActionsQuery({
    page,
    size: 10,
  })
  const [deleteAction, { isLoading: isDeleting, data: deleteActionResponse }] =
    useDeleteActionMutation()

  const handleDelete = (groupId: string) => {
    return deleteAction(groupId)
  }
  useEffect(() => {
    if (deleteActionResponse && deleteActionResponse.errors === null) {
      refetch()
      notification.success({
        message: 'Success',
        description: `Successfully deleted action`,
      })
    }
  }, [deleteActionResponse, refetch])

  const onCancel = useCallback(
    (withRefresh: boolean) => {
      setOpenCreateModal(false)
      if (withRefresh) refetch()
    },
    [refetch]
  )

  return (
    <div css={{ padding: '1rem' }}>
      <CreateAction open={openCreateModal} onClose={onCancel} />
      <div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '1.5rem',
        }}
      >
        <div css={{ fontSize: '1.25rem', fontWeight: '500' }}>
          Action Managements
        </div>
        <Button type="primary" onClick={() => setOpenCreateModal(true)}>
          Add new action
        </Button>
      </div>
      <Table
        loading={isLoading || isFetching}
        dataSource={data?.data}
        css={{ marginTop: '1rem' }}
        columns={[
          {
            title: 'ID',
            dataIndex: 'id',
          },
          {
            title: 'Name',
            dataIndex: 'name',
          },
          {
            title: 'Description',
            dataIndex: 'description',
          },
          {
            title: 'Method',
            dataIndex: 'method',
            render: (value) => {
              return <Tag color={getMethodColor(value)}>{value}</Tag>
            },
          },
          {
            title: 'Path',
            dataIndex: 'path',
          },
          {
            title: 'Action',
            align: 'right',
            render: (_text, record) => (
              <Popconfirm
                onConfirm={() => handleDelete(record.id)}
                title="Delete action"
                description={`Are you sure to delete this action`}
                okButtonProps={{ loading: isDeleting }}
              >
                <Button danger size="small" icon={<DeleteOutlined />}>
                  Delete
                </Button>
              </Popconfirm>
            ),
          },
        ]}
        pagination={false}
      />

      <Pagination
        css={{ marginTop: '1rem', textAlign: 'right' }}
        pageSize={10}
        current={page}
        onChange={(page) => setPage(page)}
        total={data?.pagination?.total || data?.data?.length}
        showTotal={(total) => `Total ${total} groups`}
      />
    </div>
  )
}
