import { Form, Input, Modal, ModalProps, notification, Select } from 'antd'
import { useEffect } from 'react'
import { useAllServicesQuery, useCreateResourceMutation } from 'services/authz'

interface Props extends ModalProps {
  onClose: (withRefresh: boolean) => void
}
export default function CreateNewGroup({ onClose, ...props }: Props) {
  const [form] = Form.useForm<{ name: string; service_id: string }>()

  const { data: services } = useAllServicesQuery({ page: 1, size: 1000 })
  const [createResource, { isLoading, data, error }] =
    useCreateResourceMutation()

  useEffect(() => {
    if (!data?.errors && data?.data.name) {
      notification.success({
        message: 'Success',
        description: `Successfully created resource ${data?.data.name}`,
      })
      onClose(true)
    }
  }, [data, onClose])

  useEffect(() => {
    if (error) {
      notification.error({
        message: 'Error',
        description: (error as any)?.data?.errors?.detail,
      })
    }
  }, [error])

  const onFinish = ({
    name,
    service_id,
  }: {
    name: string
    service_id: string
  }) => {
    createResource({ name, service_id })
  }
  return (
    <Modal
      title="Create new resource"
      centered
      okText="Create"
      onCancel={() => onClose(false)}
      okButtonProps={{ htmlType: 'submit', loading: isLoading }}
      onOk={form.submit}
      {...props}
    >
      <Form layout="vertical" onFinish={onFinish} form={form}>
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: 'Please input group name' }]}
        >
          <Input placeholder="Group name..." />
        </Form.Item>
        <Form.Item
          name="service_id"
          label="Service"
          rules={[{ required: true, message: 'Please select service ' }]}
        >
          <Select
            placeholder="Select service"
            options={services?.data?.map((item) => ({
              name: item.name,
              value: item.id,
            }))}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}
