/** @jsxImportSource @emotion/react */
import { LockOutlined, UserOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'
import { Button, Form, Input, notification } from 'antd'
import { ReactComponent as LogoFull } from 'assets/logo-full.svg'
import ForgotPassword from 'components/ForgotPassword'
import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useLoginMutation } from 'services/auth'
import { LoginPayload } from 'services/types/auth'
import { setCredentals } from 'store/auth'
import { useAppDispatch } from 'store/hooks'
import { theme } from 'theme'

const LoginPage = styled.div({
  background: theme.bg1,
  width: '100vw',
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

const Footer = styled.div({
  position: 'fixed',
  bottom: '1rem',
})

const LoginForm = styled.div({
  boxShadow: theme.boxShadow,
  background: theme.bg2,
  padding: '1.5rem',
  borderRadius: '1rem',
  marginTop: '-4rem',
  position: 'relative',
  overflow: 'hidden',
})

const Logo = styled(LogoFull)({
  '& path': {
    fill: theme.primary,
    stroke: theme.primary,
  },
})

const StyledForm = styled(Form)({
  width: '300px',
})

export default function Login() {
  const [showForgot, setShowForgot] = useState(false)
  const [login, { data, error, isLoading, reset }] = useLoginMutation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const onFinish = (values: any) => {
    login(values as LoginPayload)
  }

  useEffect(() => {
    if (error) {
      notification.error({
        message: 'Error',
        description:
          (error as any)?.data?.errors?.[0]?.message || 'Somethings went wrong',
      })
      reset()
    }
  }, [error, reset])

  useEffect(() => {
    if (data) {
      dispatch(
        setCredentals({
          accessToken: data.access_token,
          refreshToken: data.refresh_token,
        })
      )
      navigate('/admin/', { replace: true })
    }
  }, [data, dispatch, navigate])

  return (
    <LoginPage>
      <LoginForm>
        <ForgotPassword
          getContainer={false}
          open={showForgot}
          onClose={() => setShowForgot(false)}
        />

        <div
          css={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '2rem',
          }}
        >
          <Logo />
        </div>

        <StyledForm
          name="login"
          layout="vertical"
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <Form.Item
            name="email"
            label="Email"
            rules={[{ required: true, message: 'Please input your Email!' }]}
          >
            <Input prefix={<UserOutlined />} placeholder="Email" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please input your Password!' }]}
            label="Password"
          >
            <Input
              prefix={<LockOutlined />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item>
            <Link
              css={{ float: 'right' }}
              to=""
              onClick={() => setShowForgot(true)}
            >
              Forgot password
            </Link>
          </Form.Item>

          <Button
            type="primary"
            htmlType="submit"
            css={{ width: '100%' }}
            loading={isLoading}
          >
            Log in
          </Button>
        </StyledForm>
      </LoginForm>

      <Footer>Datapal Team ©2022</Footer>
    </LoginPage>
  )
}
