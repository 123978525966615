import Sidebar from './Sidebar'
import Header from './Header'
import Content from './Content'

import { Drawer, Layout, notification } from 'antd'
import { useEffect, useState } from 'react'
import useWindowSize from 'hooks/useWindowSize'
import styled from '@emotion/styled'
import { useAuth } from 'store/auth/hooks'
import { Navigate, useNavigate } from 'react-router-dom'
import { useGetUserRoleMutation } from 'services/authz'
import { parseJwt } from 'utils/jwt'

const StyledLayout = styled(Layout)({
  height: '100vh',
})

function AdminLayout() {
  const { isLoggedIn, accessToken } = useAuth()
  const [collapsed, setCollapsed] = useState(false)
  const [screenWidth] = useWindowSize()
  const [getRole, { data }] = useGetUserRoleMutation()
  const navigate = useNavigate()
  const isMoblie = screenWidth < 768

  const toggleCollapse = () => setCollapsed((prev) => !prev)

  useEffect(() => {
    if (accessToken) {
      const { email } = parseJwt(accessToken)
      getRole(email)
    }
  }, [accessToken, getRole])

  useEffect(() => {
    if (data) {
      if (
        !data?.data ||
        data?.data?.length === 0 ||
        (data?.data?.indexOf('datapal_admin') === -1 &&
          data?.data?.indexOf('admin') === -1)
      ) {
        notification.error({
          message: 'Error',
          description: 'Please log in with admin account',
        })

        navigate('/admin/login')
      }
    }
  }, [data, navigate])

  if (!isLoggedIn) return <Navigate to="/admin/login" replace />

  return (
    <StyledLayout>
      {isMoblie ? (
        <Drawer
          maskClosable
          closable={false}
          onClose={toggleCollapse}
          open={!collapsed}
          placement="left"
          bodyStyle={{ padding: 0 }}
        >
          <Sidebar
            isMobile={isMoblie}
            collapsed={false}
            onCollapse={toggleCollapse}
          />
        </Drawer>
      ) : (
        <>
          {data?.data && (
            <Sidebar collapsed={collapsed} onCollapse={toggleCollapse} />
          )}
        </>
      )}
      {data?.data && (
        <Layout>
          <Header toggleCollapse={toggleCollapse} collapsed={collapsed} />
          <Content />
        </Layout>
      )}
    </StyledLayout>
  )
}

export default AdminLayout
