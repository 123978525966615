/** @jsxImportSource @emotion/react */
import { Breadcrumb, Layout } from 'antd'
import { Route, Routes, Link, useLocation } from 'react-router-dom'
import routes from 'routes'
import { pathToRegexp } from 'path-to-regexp'
import { Route as IRoute } from 'types'
import styled from '@emotion/styled'
import { theme } from 'theme'

const { Content } = Layout

const ContentWrapper = styled.div({
  padding: '1rem',
  overflow: 'auto',
  background: theme.bg1,
})

const queryAncestors = (routeList: IRoute[], currentRoute: IRoute) => {
  const res = [currentRoute]
  const hashMap = new Map()
  routeList.forEach((route) => hashMap.set(route.id, route))

  const getPath = (current: IRoute) => {
    const currentParentId = hashMap.get(current.id).breadcrumbParentId
    if (currentParentId) {
      res.push(hashMap.get(currentParentId))
      getPath(hashMap.get(currentParentId))
    }
  }

  getPath(currentRoute)
  return res
}

function AppContent() {
  const appRoutes = routes.filter((route) => route.path)
  const { pathname } = useLocation()

  const currentRoute = appRoutes.find(
    (route) => route.path && pathToRegexp(route.path).exec(pathname)
  )

  const paths = currentRoute
    ? queryAncestors(appRoutes, currentRoute).reverse()
    : [appRoutes[0], { id: '404', name: 'Not Found', path: '/404' }]

  return (
    <ContentWrapper>
      <Breadcrumb css={{ marginBottom: '1rem' }} separator=">">
        {paths.map((path, index) => {
          return (
            <Breadcrumb.Item key={path.id}>
              {index === paths.length - 1 ? (
                <>
                  {path.icon && (
                    <span css={{ marginRight: '0.25rem' }}>
                      <path.icon />
                    </span>
                  )}
                  <span>{path.name}</span>
                </>
              ) : (
                <Link to={path.path || ''}>
                  {path.icon && (
                    <span css={{ marginRight: '0.25rem' }}>
                      <path.icon />
                    </span>
                  )}
                  <span>{path.name}</span>
                </Link>
              )}
            </Breadcrumb.Item>
          )
        })}
      </Breadcrumb>

      <Content
        css={{
          background: theme.bg2,
          minHeight: '100vh !important',
          boxShadow: theme.boxShadow,
        }}
      >
        <Routes>
          {appRoutes.map(
            (route) =>
              route.component && (
                <Route
                  key={route.id}
                  path={route.path}
                  element={<route.component />}
                />
              )
          )}
        </Routes>
      </Content>
    </ContentWrapper>
  )
}

export default AppContent
