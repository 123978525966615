/** @jsxImportSource @emotion/react */
import { DeleteOutlined } from '@ant-design/icons'
import { Button, Pagination, Popconfirm, Table, notification } from 'antd'
import { UserGroupModel } from 'models'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  useDeleteUserGroupMutation,
  useUsersByGroupQuery,
} from 'services/authz'

export default function GroupDetail() {
  const { id } = useParams()
  const [page, setPage] = useState(1)

  const { data, isLoading, refetch } = useUsersByGroupQuery({
    id: id || '',
    params: { page: page, size: 10 },
  })

  const [
    deleteUserGroup,
    { isLoading: isDeleting, data: deleteActionResponse },
  ] = useDeleteUserGroupMutation()

  const handleDelete = useCallback(
    (user_id: string, group_id: string) => {
      deleteUserGroup({
        user_id,
        group_id,
      })
    },
    [deleteUserGroup]
  )

  useEffect(() => {
    if (deleteActionResponse && deleteActionResponse.errors === null) {
      refetch()
      notification.success({
        message: 'Success',
        description: `Successfully deleted user group`,
      })
    }
  }, [deleteActionResponse, refetch])

  const columns = useMemo(() => {
    return [
      {
        title: 'Id',
        dataIndex: 'id',
      },
      {
        title: 'User Id',
        dataIndex: 'user_id',
      },
      {
        title: 'Action',
        render: (_text: any, record: UserGroupModel) => (
          <Popconfirm
            onConfirm={() => handleDelete(record.user_id, record.group_id)}
            title="Delete user"
            description={`Are you sure to delete this user from group`}
            okButtonProps={{ loading: isDeleting }}
          >
            <Button danger size="small" icon={<DeleteOutlined />}>
              Delete
            </Button>
          </Popconfirm>
        ),
      },
    ]
  }, [handleDelete, isDeleting])

  return (
    <div css={{ padding: '1rem' }}>
      <div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '1.5rem',
        }}
      >
        <div css={{ fontSize: '1.25rem', fontWeight: '500' }}>
          Group: <b>{id}</b>
        </div>
      </div>

      <Table
        loading={isLoading}
        dataSource={data?.data}
        css={{ marginTop: '1rem' }}
        rowKey="id"
        columns={columns}
        pagination={false}
      />

      <Pagination
        css={{ marginTop: '1rem', textAlign: 'right' }}
        pageSize={10}
        current={page}
        onChange={(page) => setPage(page)}
        total={data?.pagination.total}
        showTotal={(total) => `Total ${total} groups`}
      />
    </div>
  )
}
