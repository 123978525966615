import './App.css'
import 'antd/dist/reset.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Login from 'pages/Login'
import AdminLayout from 'components/Layout'
import { useEffect } from 'react'

export default function App() {
  useEffect(() => {
    document.body.dataset.theme = 'light'
  }, [])

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/admin/login" element={<Login />} />
        <Route path="*" element={<AdminLayout />} />
      </Routes>
    </BrowserRouter>
  )
}
