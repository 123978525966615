import { AppState } from 'store'
import { logout, setCredentals } from 'store/auth'
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react'
import { Mutex } from 'async-mutex'

import {
  AddGroupPolicyModel,
  AddUserGroupModel,
  GroupPolicyModel,
  GroupPolicyParams,
  UserGroupModel,
} from 'models'
import { LoginResponse } from './types/auth'

interface Pagination {
  page_size: number
  page_number: number
  total: number
}

interface Error {
  code: number
  detail: string
}

interface Response<T> {
  pagination: Pagination
  errors: Error | null
  data: T
}

export interface Group {
  id: string
  name: string
  description: string
}

export interface Service {
  id: string
  name: string
  description: string
}

export interface Resource {
  id: string
  path: string
  name: string
  service_id: string
  type: string
  deleted: boolean
  created_at: string
  updated_at: string
}

export interface Policy {
  id: string
  name: string
  description: string
  action_id: string
  type: string
  resource_id: string
  resource_path: string
}

export interface Action {
  id: string
  service_id: string
  method: string
  path: string
  name: string
  description: string
}

interface PaginationParams {
  page: number
  size: number
}

export const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_AUTHZ_URL,
  prepareHeaders: (headers, { getState }) => {
    // By default, if we have a token in the store, let's use that for authenticated requests
    const token = (getState() as AppState).auth.accessToken
    if (token) {
      headers.set('authorization', `Bearer ${token}`)
    }
    return headers
  },
})

const mutex = new Mutex()

const baseQueryWithInterceptor: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  await mutex.waitForUnlock()

  let result = await baseQuery(args, api, extraOptions)
  if (result.error && result.error.status === 401) {
    // Using mutex to lock query, only 1 refresh should be call
    if (!mutex.isLocked()) {
      const release = await mutex.acquire()
      try {
        const refreshResult = await baseQuery(
          {
            url: '/users/refresh-token/',
            method: 'POST',
            body: {
              refresh_token: (api.getState() as AppState).auth.refreshToken,
            },
          },
          api,
          extraOptions
        )

        if (refreshResult.data) {
          const res = refreshResult.data as LoginResponse

          api.dispatch(
            setCredentals({
              accessToken: res.access_token,
              refreshToken: res.refresh_token,
            })
          )

          result = await baseQuery(args, api, extraOptions)
        } else {
          api.dispatch(logout())
        }
      } finally {
        release()
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock()
      result = await baseQuery(args, api, extraOptions)
    }
  }

  return result
}

export const authzApi = createApi({
  reducerPath: 'authzApi',
  baseQuery: baseQueryWithInterceptor,
  endpoints: (builder) => ({
    allGroups: builder.query<Response<Group[]>, PaginationParams>({
      query: (params) => ({
        url: '/authz/group/all',
        params,
      }),
    }),
    createGroup: builder.mutation<
      Response<Group>,
      { name: string; description: string }
    >({
      query: (body) => ({
        url: '/authz/group',
        method: 'POST',
        body,
      }),
    }),
    deleteGroup: builder.mutation<Response<string>, string>({
      query: (id) => ({
        url: `/authz/group/${id}`,
        method: 'DELETE',
      }),
    }),
    getUserRole: builder.mutation<Response<string[]>, string>({
      query: (email) => ({
        url: `/authz/user_group/${email}`,
        method: 'GET',
      }),
    }),

    // Resource=================================
    allResources: builder.query<Response<Resource[]>, PaginationParams>({
      query: (params) => ({
        url: '/authz/resource/all',
        params,
      }),
    }),
    CreateResource: builder.mutation<
      Response<Group>,
      { name: string; service_id: string }
    >({
      query: (body) => ({
        url: '/authz/resource',
        method: 'POST',
        body,
      }),
    }),
    deleteResource: builder.mutation<Response<string>, string>({
      query: (id) => ({
        url: `/authz/resource/${id}`,
        method: 'DELETE',
      }),
    }),

    // Service =================================
    allServices: builder.query<Response<Service[]>, PaginationParams>({
      query: (params) => ({
        url: '/authz/service/all',
        params,
      }),
    }),
    deleteService: builder.mutation<Response<string>, string>({
      query: (id) => ({
        url: `/authz/service/${id}`,
        method: 'DELETE',
      }),
    }),
    createService: builder.mutation<
      Response<Service>,
      { name: string; description: string }
    >({
      query: (body) => ({
        url: '/authz/service',
        method: 'POST',
        body,
      }),
    }),

    // Policies ================================
    allPolicies: builder.query<Response<Policy[]>, PaginationParams>({
      query: (params) => ({
        url: '/authz/policy/all',
        params,
      }),
    }),

    createPolicy: builder.mutation<
      Response<Policy>,
      {
        action_id: string
        resource_id: string | number
        name: string
        description: string
        conditions: any
        type: string
      }
    >({
      query: (body) => ({
        url: '/authz/policy/',
        method: 'POST',
        body,
      }),
    }),

    // Actions ================================
    allActions: builder.query<Response<Action[]>, PaginationParams>({
      query: (params) => ({
        url: '/authz/action/all',
        params,
      }),
    }),
    deleteAction: builder.mutation<Response<string>, string>({
      query: (id) => ({
        url: `/authz/action/${id}`,
        method: 'DELETE',
      }),
    }),
    createAction: builder.mutation<
      Response<Action>,
      {
        name: string
        description: string
        path: string
        method: string
        service_id: string
      }
    >({
      query: (body) => ({
        url: '/authz/action',
        method: 'POST',
        body,
      }),
    }),

    // Group Policy ================================
    allGroupPolices: builder.query<
      Response<GroupPolicyModel[]>,
      GroupPolicyParams
    >({
      query: (params) => ({
        url: '/authz/group_policy/all',
        params,
      }),
    }),

    deleteGroupPolicy: builder.mutation<Response<string>, string>({
      query: (id) => ({
        url: `/authz/group_policy/${id}`,
        method: 'DELETE',
      }),
    }),

    createGroupPolicies: builder.mutation<
      { data: string; errors: string },
      AddGroupPolicyModel
    >({
      query: (body) => ({
        url: '/authz/group_policy/bulk/',
        method: 'POST',
        body,
      }),
    }),

    // User Group ================================
    allUserGroups: builder.query<Response<UserGroupModel[]>, PaginationParams>({
      query: (params) => ({
        url: '/authz/user_group/all',
        params,
      }),
    }),

    usersByGroup: builder.query<
      Response<UserGroupModel[]>,
      { id: string; params: PaginationParams }
    >({
      query: (queryParams) => ({
        url: `/authz/user_group/group_id/${queryParams.id}`,
        params: queryParams.params,
      }),
    }),

    deleteUserGroup: builder.mutation<
      Response<string>,
      { group_id: string; user_id: string }
    >({
      query: (body) => ({
        url: `/authz/user_group/user`,
        method: 'DELETE',
        body,
      }),
    }),

    createUserGroup: builder.mutation<
      { data: string; errors: string },
      AddUserGroupModel
    >({
      query: (body) => ({
        url: '/authz/user_group/user/',
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const {
  useAllGroupsQuery,
  useDeleteGroupMutation,
  useCreateGroupMutation,
  useGetUserRoleMutation,

  useAllResourcesQuery,
  useCreateResourceMutation,
  useDeleteResourceMutation,

  useAllServicesQuery,
  useDeleteServiceMutation,
  useCreateServiceMutation,

  useAllPoliciesQuery,
  useCreatePolicyMutation,

  useAllActionsQuery,
  useCreateActionMutation,
  useDeleteActionMutation,

  useAllGroupPolicesQuery,
  useCreateGroupPoliciesMutation,
  useDeleteGroupPolicyMutation,

  useAllUserGroupsQuery,
  useUsersByGroupQuery,
  useCreateUserGroupMutation,
  useDeleteUserGroupMutation,
} = authzApi
