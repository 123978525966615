/** @jsxImportSource @emotion/react */
import { ArrowLeftOutlined, KeyOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'
import { Button, Drawer, DrawerProps, Form, Input, notification } from 'antd'
import { useEffect, useRef, useState } from 'react'
import {
  useConfirmForgotPasswordMutation,
  useForgotPasswordMutation,
} from 'services/auth'
import { theme } from 'theme'

const Icon = styled(KeyOutlined)({
  fontSize: '28px',
  padding: '12px',
  transform: 'rotate(180deg)',
  borderRadius: '999px',
  background: theme.primary,
  margin: 'auto',
})

export default function ForgotPassword(props: DrawerProps) {
  const [email, setEmail] = useState('')
  const ref = useRef<HTMLButtonElement>(null)

  const [
    confirm,
    { isLoading: isConfirming, error: confirmError, data: confirmData },
  ] = useConfirmForgotPasswordMutation()

  const [forgotPassword, { isLoading, error, data }] =
    useForgotPasswordMutation()

  const onFinish = (values: { email: string }) => {
    setEmail(values.email)
    forgotPassword(values.email)
  }

  useEffect(() => {
    if (confirmData) {
      notification.success({
        message: 'Success',
        description: 'Reset password successful',
      })
      ref.current?.click()
    }
  }, [confirmData])

  useEffect(() => {
    if (confirmError) {
      notification.error({
        message: 'Error',
        description:
          (confirmError as any)?.data?.errors?.[0]?.message ||
          'Somethings went wrong',
      })
    }
  }, [confirmError])

  useEffect(() => {
    if (error) {
      notification.error({
        message: 'Error',
        description:
          (error as any)?.data?.errors?.[0]?.message || 'Somethings went wrong',
      })
    }
  }, [error])

  useEffect(() => {
    if (data) {
      notification.success({
        message: 'Success',
        description: 'We sent an confirmation code to your email!',
      })
    }
  }, [data])

  return (
    <Drawer
      mask={false}
      headerStyle={{ display: 'none' }}
      placement="right"
      width="100%"
      bodyStyle={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
      {...props}
    >
      <div css={{ flex: 1 }}>
        {!data && (
          <div css={{ textAlign: 'center' }}>
            <Icon />
          </div>
        )}
        <div
          css={{
            fontSize: '24px',
            fontWeight: '500',
            marginTop: '1rem',
            textAlign: 'center',
          }}
        >
          Forgot password
        </div>

        {!data && (
          <div
            css={{
              color: theme.subText,
              marginTop: '0.5rem',
              textAlign: 'center',
            }}
          >
            Enter your email to reset password
          </div>
        )}

        {data ? (
          <Form
            layout="vertical"
            onFinish={(values) => {
              confirm({
                email,
                password: values.password,
                confirmation_code: values.confirmation_code,
              })
            }}
          >
            <Form.Item
              name="confirmation_code"
              label="Confirmation code"
              css={{ marginTop: '1rem' }}
              rules={[
                {
                  required: true,
                  message: 'Please input your confirmation code!',
                },
              ]}
            >
              <Input placeholder="Confirmation code" />
            </Form.Item>

            <Form.Item
              name="password"
              label="New password"
              css={{ marginTop: '1rem' }}
              rules={[
                {
                  required: true,
                  message: 'Please input your new password',
                },
              ]}
            >
              <Input placeholder="Enter your new password" type="password" />
            </Form.Item>

            <Button
              loading={isConfirming}
              type="primary"
              css={{ width: '100%', marginTop: '1rem' }}
              htmlType="submit"
            >
              Reset password
            </Button>
          </Form>
        ) : (
          <Form layout="vertical" onFinish={onFinish}>
            <Form.Item
              name="email"
              label="Email"
              css={{ marginTop: '1rem' }}
              rules={[
                {
                  required: true,
                  message: 'Please input your Email!',
                },
                {
                  type: 'email',
                  message: 'Email is not valid',
                },
              ]}
            >
              <Input placeholder="Enter your email" />
            </Form.Item>
            <Button
              loading={isLoading}
              type="primary"
              css={{ width: '100%', marginTop: '1rem' }}
              htmlType="submit"
            >
              Reset password
            </Button>
          </Form>
        )}
      </div>

      <Button type="link" onClick={props.onClose} ref={ref}>
        <ArrowLeftOutlined />
        Back to login
      </Button>
    </Drawer>
  )
}
