/** @jsxImportSource @emotion/react */
import { DeleteOutlined } from '@ant-design/icons'
import { Button, notification, Pagination, Popconfirm, Table } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import {
  Group,
  useAllGroupPolicesQuery,
  useAllGroupsQuery,
  useDeleteGroupPolicyMutation,
} from 'services/authz'
import { TablePaginationConfig } from 'antd/lib/table/interface'
import { FilterValue } from 'antd/es/table/interface'
import CreateNewGroupPolicy from './CreateNewGroupPolicy'

export default function GroupPolicies() {
  const [openCreateModal, setOpenCreateModal] = useState(false)
  const [page, setPage] = useState(1)

  const [groupFilter, setGroupFilter] = useState<string>('')

  const { data, isLoading, isFetching, refetch } = useAllGroupPolicesQuery({
    page,
    size: 25,
    group_id: groupFilter,
  })

  const { data: groupList, isLoading: isGetGroupsLoading } = useAllGroupsQuery({
    page,
    size: 200,
  })

  const onCancel = useCallback(
    (withRefresh: boolean) => {
      setOpenCreateModal(false)
      if (withRefresh) refetch()
    },
    [refetch]
  )

  const [deleteAction, { isLoading: isDeleting, data: deleteActionResponse }] =
    useDeleteGroupPolicyMutation()

  const handleDelete = (groupId: string) => {
    return deleteAction(groupId)
  }
  useEffect(() => {
    if (deleteActionResponse && deleteActionResponse.errors === null) {
      refetch()
      notification.success({
        message: 'Success',
        description: `Successfully deleted group policy`,
      })
    }
  }, [deleteActionResponse, refetch])

  const handleTableChange = (
    _pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>
  ) => {
    // @ts-ignore: Unreachable code error
    setGroupFilter(filters?.group_id ? filters?.group_id[0] : undefined)
  }

  return (
    <div css={{ padding: '1rem' }}>
      {openCreateModal && (
        <CreateNewGroupPolicy
          open={openCreateModal}
          onClose={onCancel}
          onSuccess={refetch}
        />
      )}
      <div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '1.5rem',
        }}
      >
        <div css={{ fontSize: '1.25rem', fontWeight: '500' }}>Group Policy</div>
        <Button type="primary" onClick={() => setOpenCreateModal(true)}>
          Create new group polices
        </Button>
      </div>
      <Table
        loading={isLoading || isFetching || isGetGroupsLoading}
        dataSource={data?.data}
        css={{ marginTop: '1rem' }}
        columns={[
          {
            title: 'ID',
            dataIndex: 'id',
          },
          {
            title: 'Group Id',
            dataIndex: 'group_id',
            filters: groupList?.data?.map((group: Group) => ({
              text: group.name,
              value: group.id,
            })),
            filterMultiple: false,
          },
          {
            title: 'Name',
            dataIndex: 'name',
          },
          {
            title: 'Action',
            dataIndex: 'action_id',
          },
          {
            title: 'Resource',
            dataIndex: 'resource_path',
          },
          {
            title: 'Actions',
            align: 'right',
            render: (_text, record) => (
              <Popconfirm
                onConfirm={() => handleDelete(record.id.toString())}
                title="Delete group policy"
                description={`Are you sure to delete this group policy?`}
                okButtonProps={{ loading: isDeleting }}
              >
                <Button danger size="small" icon={<DeleteOutlined />}>
                  Delete
                </Button>
              </Popconfirm>
            ),
          },
        ]}
        rowKey="id"
        onChange={handleTableChange}
        pagination={false}
      />

      <Pagination
        css={{ marginTop: '1rem', textAlign: 'right' }}
        pageSize={10}
        current={page}
        onChange={(page) => setPage(page)}
        total={data?.pagination.total}
        showTotal={(total) => `Total ${total} groups`}
      />
    </div>
  )
}
