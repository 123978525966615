/** @jsxImportSource @emotion/react */
import { DeleteOutlined } from '@ant-design/icons'
import { Button, notification, Pagination, Popconfirm, Table } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { useAllServicesQuery, useDeleteServiceMutation } from 'services/authz'
import CreateNewService from './CreateService'

export default function Services() {
  const [openCreateModal, setOpenCreateModal] = useState(false)
  const [page, setPage] = useState(1)

  const { data, isLoading, isFetching, refetch } = useAllServicesQuery({
    page: 1,
    size: 1000,
  })
  const [
    deleteService,
    { isLoading: isDeleting, data: deleteServiceResponse },
  ] = useDeleteServiceMutation()

  const handleDelete = (groupId: string) => {
    return deleteService(groupId)
  }
  useEffect(() => {
    if (deleteServiceResponse && deleteServiceResponse.errors === null) {
      refetch()
      notification.success({
        message: 'Success',
        description: `Successfully deleted service`,
      })
    }
  }, [deleteServiceResponse, refetch])

  const onCancel = useCallback(
    (withRefresh: boolean) => {
      setOpenCreateModal(false)
      if (withRefresh) refetch()
    },
    [refetch]
  )

  return (
    <div css={{ padding: '1rem' }}>
      <CreateNewService open={openCreateModal} onClose={onCancel} />
      <div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '1.5rem',
        }}
      >
        <div css={{ fontSize: '1.25rem', fontWeight: '500' }}>
          Service Managements
        </div>
        <Button type="primary" onClick={() => setOpenCreateModal(true)}>
          Add new service
        </Button>
      </div>
      <Table
        loading={isLoading || isFetching}
        dataSource={data?.data?.slice((page - 1) * 10, page * 10)}
        css={{ marginTop: '1rem' }}
        columns={[
          {
            title: 'ID',
            dataIndex: 'id',
          },
          {
            title: 'Name',
            dataIndex: 'name',
          },
          {
            title: 'Description',
            dataIndex: 'description',
          },
          {
            title: 'Action',
            align: 'right',
            render: (_text, record) => (
              <Popconfirm
                onConfirm={() => handleDelete(record.id)}
                title="Delete service"
                description={`Are you sure to delete this service`}
                okButtonProps={{ loading: isDeleting }}
              >
                <Button danger size="small" icon={<DeleteOutlined />}>
                  Delete
                </Button>
              </Popconfirm>
            ),
          },
        ]}
        pagination={false}
      />

      <Pagination
        css={{ marginTop: '1rem', textAlign: 'right' }}
        pageSize={10}
        current={page}
        onChange={(page) => setPage(page)}
        total={data?.pagination?.total || data?.data?.length}
        showTotal={(total) => `Total ${total} groups`}
      />
    </div>
  )
}
