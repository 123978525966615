import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'
import { Avatar, Layout, Menu } from 'antd'
import { useAuth } from 'store/auth/hooks'
import { theme } from 'theme'

const { Header } = Layout
const { SubMenu } = Menu

const StyledHeader = styled(Header)({
  position: 'sticky',
  top: 0,
  boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
  background: theme.bg2 + ' !important',
  padding: '0 !important',
  display: 'flex',
  justifyContent: 'space-between',
})

const CollapseIcon = styled.div({
  width: '64px',
  textAlign: 'center',
  cursor: 'pointer',
  color: theme.link,
  fontSize: '18px',
  ':hover': {
    background: theme.bg1,
  },
})

function AppHeader({
  collapsed,
  toggleCollapse,
}: {
  collapsed: boolean
  toggleCollapse: () => void
}) {
  const { logout } = useAuth()
  const handleClickMenu = () => {
    console.warn('Empty handleClickMenu')
  }

  return (
    <StyledHeader>
      <CollapseIcon role="button" onClick={toggleCollapse}>
        {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
      </CollapseIcon>
      <Menu key="user" mode="horizontal" onClick={handleClickMenu}>
        <SubMenu
          title={
            <>
              <span style={{ color: '#999', marginRight: 4 }}>Hi,</span>
              <span>Soleil</span>
              <Avatar
                style={{ marginLeft: 8 }}
                src="https://scontent.fhan5-4.fna.fbcdn.net/v/t1.0-9/138399758_1897937860375528_7226162987715132143_o.jpg?_nc_cat=104&ccb=2&_nc_sid=09cbfe&_nc_ohc=-bGj0r_lQZwAX-T7qqk&_nc_ht=scontent.fhan5-4.fna&oh=b94c0dc3731432702fa2ee28fca32624&oe=60317D50"
              />
            </>
          }
        >
          <Menu.Item key="SignOut" onClick={logout}>
            Sign out
          </Menu.Item>
        </SubMenu>
      </Menu>
    </StyledHeader>
  )
}

export default AppHeader
