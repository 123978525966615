import { Form, Input, Modal, ModalProps, notification } from 'antd'
import { AddOrganizationModel } from 'models'
import { useEffect } from 'react'
import { useCreateOrganizationMutation } from 'services/auth'

interface Props extends ModalProps {
  onClose: (withRefresh: boolean) => void
}
export default function CreateNewOrganization({ onClose, ...props }: Props) {
  const [form] = Form.useForm<AddOrganizationModel>()

  const [createOrganization, { isLoading, data, error }] =
    useCreateOrganizationMutation()

  useEffect(() => {
    if (!data?.errors && data?.data.name) {
      notification.success({
        message: 'Success',
        description: `Successfully created organization ${data?.data.name}`,
      })
      onClose(true)
    }
  }, [data, onClose])

  useEffect(() => {
    if (error) {
      notification.error({
        message: 'Error',
        description: (error as any)?.data?.errors?.detail,
      })
    }
  }, [error])

  const onFinish = ({ name, description }: AddOrganizationModel) => {
    createOrganization({ name, description })
  }
  return (
    <Modal
      title="Create new organization"
      centered
      okText="Create"
      onCancel={() => onClose(false)}
      okButtonProps={{ htmlType: 'submit', loading: isLoading }}
      onOk={form.submit}
      {...props}
    >
      <Form layout="vertical" onFinish={onFinish} form={form}>
        <Form.Item
          name="name"
          label="Name"
          rules={[
            { required: true, message: 'Please input organization name' },
          ]}
        >
          <Input placeholder="Organization name..." />
        </Form.Item>

        <Form.Item name="description" label="Description">
          <Input placeholder="Organization description..." />
        </Form.Item>
      </Form>
    </Modal>
  )
}
