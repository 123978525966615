import { Form, Modal, ModalProps, Select, notification } from 'antd'
import { useEffect } from 'react'
import {
  Action,
  Resource,
  useAllActionsQuery,
  useAllResourcesQuery,
  useCreatePolicyMutation,
} from 'services/authz'

interface Props extends ModalProps {
  onClose: (withRefresh: boolean) => void
}
export default function CreateNewGroup({ onClose, ...props }: Props) {
  const [form] = Form.useForm<{ action_id: string; resource_id: string }>()

  const [createPolicy, { isLoading, data, error }] = useCreatePolicyMutation()
  const { data: allActions, isLoading: isGettingActions } = useAllActionsQuery({
    page: 1,
    size: 999,
  })

  const { data: allResources, isLoading: isGettingResources } =
    useAllResourcesQuery({
      page: 1,
      size: 999,
    })

  useEffect(() => {
    if (!data?.errors && data?.data.name) {
      notification.success({
        message: 'Success',
        description: `Successfully created group ${data?.data.name}`,
      })
      form.resetFields()
      onClose(true)
    }
  }, [data, form, onClose])

  useEffect(() => {
    if (error) {
      notification.error({
        message: 'Error',
        description: (error as any)?.data?.errors?.detail,
      })
    }
  }, [error])

  const onFinish = ({
    action_id,
    resource_id,
  }: {
    action_id: string
    resource_id: string
  }) => {
    createPolicy({
      action_id,
      resource_id,
      name: action_id,
      description: action_id,
      conditions: {},
      type: 'user',
    })
  }

  const handleFilterOption = (
    inputValue: string,
    option: {
      label: string
      value: string
    }
  ) => {
    return (
      option.value
        .toString()
        .toLowerCase()
        .includes(inputValue?.toLowerCase()) ||
      option.label.toLowerCase().includes(inputValue?.toLowerCase())
    )
  }

  return (
    <Modal
      title="Create new policy"
      centered
      okText="Create"
      onCancel={() => onClose(false)}
      okButtonProps={{ htmlType: 'submit', loading: isLoading }}
      onOk={form.submit}
      {...props}
    >
      <Form layout="vertical" onFinish={onFinish} form={form}>
        <Form.Item
          name="action_id"
          label="Action"
          rules={[{ required: true, message: 'Please select action' }]}
        >
          <Select
            placeholder="Select action..."
            disabled={isGettingActions}
            showSearch
            // @ts-ignore: Unreachable code error
            filterOption={handleFilterOption}
            options={allActions?.data?.map((action: Action) => ({
              value: action.id,
              label: action.name,
            }))}
          />
        </Form.Item>
        <Form.Item
          name="resource_id"
          label="Resource"
          rules={[{ required: true, message: 'Please select resource' }]}
        >
          <Select
            placeholder="Select resource..."
            disabled={isGettingResources}
            showSearch
            // @ts-ignore: Unreachable code error
            filterOption={handleFilterOption}
            options={allResources?.data?.map((action: Resource) => ({
              value: action.id,
              label: action.name,
            }))}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}
