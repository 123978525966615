import {
  UserOutlined,
  DashboardOutlined,
  GroupOutlined,
  FileProtectOutlined,
  SettingOutlined,
  CustomerServiceOutlined,
  InteractionOutlined,
  CommentOutlined,
  UngroupOutlined,
} from '@ant-design/icons'
import Dashboard from 'components/Dashboard'
import Actions from 'pages/Actions/List'
import GroupManagements from 'pages/Groups/List'
import Policies from 'pages/Policies/List'
import Resources from 'pages/Resources/List'
import Services from 'pages/Services/List'
import UserManagements from 'pages/Users/UserManagements'
import Organization from 'pages/Organizations'
import { Route } from 'types'
import GroupPolicies from 'pages/GroupPolicies'
import GroupDetail from 'pages/Groups/GroupDetail'
// import UserGroup from 'pages/UserGroup'

const routes: Route[] = [
  {
    id: '1',
    icon: DashboardOutlined,
    name: 'Dashboard',
    path: '/admin/',
    component: Dashboard,
  },
  {
    id: '2',
    icon: CommentOutlined,
    breadcrumbParentId: '1',
    name: 'Organization',
    path: '/admin/organization',
    component: Organization,
  },
  {
    id: '3',
    icon: UserOutlined,
    breadcrumbParentId: '1',
    name: 'Users',
    path: '/admin/users',
    component: UserManagements,
  },
  {
    id: '4',
    icon: GroupOutlined,
    name: 'Groups',
    breadcrumbParentId: '1',
    path: '/admin/groups',
    component: GroupManagements,
  },
  {
    id: '44',
    icon: GroupOutlined,
    name: 'Groups',
    breadcrumbParentId: '2',
    path: '/admin/groups/:id',
    component: GroupDetail,
    isHidden: true,
  },
  // {
  //   id: '5',
  //   icon: GroupOutlined,
  //   name: 'User Group',
  //   breadcrumbParentId: '1',
  //   path: '/admin/user-group',
  //   component: UserGroup,
  // },
  {
    id: '6',
    icon: CustomerServiceOutlined,
    breadcrumbParentId: '1',
    name: 'Services',
    path: '/admin/services',
    component: Services,
  },
  {
    id: '7',
    icon: SettingOutlined,
    breadcrumbParentId: '1',
    name: 'Resources',
    path: '/admin/resources',
    component: Resources,
  },
  {
    id: '8',
    icon: InteractionOutlined,
    breadcrumbParentId: '1',
    name: 'Actions',
    path: '/admin/actions',
    component: Actions,
  },

  {
    id: '9',
    icon: FileProtectOutlined,
    breadcrumbParentId: '1',
    name: 'Policies',
    path: '/admin/policies',
    component: Policies,
  },
  {
    id: '10',
    icon: UngroupOutlined,
    breadcrumbParentId: '1',
    name: 'Group Policy',
    path: '/admin/group-policy',
    component: GroupPolicies,
  },
]

export default routes
