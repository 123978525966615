import { AppState } from 'store'
import { logout, setCredentals } from 'store/auth'
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react'
import { Mutex } from 'async-mutex'

import {
  OrganizationModel,
  Response,
  AddOrganizationModel,
  AddUserModel,
} from 'models'
import { LoginPayload, LoginResponse } from './types/auth'

export const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_AUTH_URL,
  prepareHeaders: (headers, { getState }) => {
    // By default, if we have a token in the store, let's use that for authenticated requests
    const token = (getState() as AppState).auth.accessToken
    if (token) {
      headers.set('authorization', `Bearer ${token}`)
    }
    return headers
  },
})

const mutex = new Mutex()

const baseQueryWithInterceptor: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  await mutex.waitForUnlock()

  let result = await baseQuery(args, api, extraOptions)
  if (result.error && result.error.status === 401) {
    // Using mutex to lock query, only 1 refresh should be call
    if (!mutex.isLocked()) {
      const release = await mutex.acquire()
      try {
        const refreshResult = await baseQuery(
          {
            url: '/users/refresh-token/',
            method: 'POST',
            body: {
              refresh_token: (api.getState() as AppState).auth.refreshToken,
            },
          },
          api,
          extraOptions
        )

        if (refreshResult.data) {
          const res = refreshResult.data as LoginResponse

          api.dispatch(
            setCredentals({
              accessToken: res.access_token,
              refreshToken: res.refresh_token,
            })
          )

          result = await baseQuery(args, api, extraOptions)
        } else {
          api.dispatch(logout())
        }
      } finally {
        release()
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock()
      result = await baseQuery(args, api, extraOptions)
    }
  }

  return result
}

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: baseQueryWithInterceptor,
  endpoints: (builder) => ({
    login: builder.mutation<LoginResponse, LoginPayload>({
      query: (credentials) => ({
        url: '/users/signin/',
        method: 'POST',
        body: credentials,
      }),
    }),

    forgotPassword: builder.mutation<void, string>({
      query: (email) => ({
        url: '/users/forgot-password/',
        method: 'POST',
        body: { email },
      }),
    }),

    confirmForgotPassword: builder.mutation<
      void,
      { confirmation_code: string; email: string; password: string }
    >({
      query: (payload) => ({
        url: '/users/confirm-forgot-password/',
        method: 'POST',
        body: payload,
      }),
    }),

    users: builder.query({
      query: (params) => ({
        url: `/admin/list`,
        params,
      }),
    }),

    createUser: builder.mutation<{ user_name: string }, AddUserModel>({
      query: (body) => ({
        url: '/admin/invitation',
        method: 'POST',
        body,
      }),
    }),

    deleteUser: builder.mutation<Response<{ id: string }>, string>({
      query: (id) => ({
        url: `/users/${id}`,
        method: 'DELETE',
      }),
    }),

    allOrganizations: builder.query<Response<OrganizationModel[]>, any>({
      query: (params) => ({
        url: '/organization/list',
        params,
      }),
    }),

    deleteOrganization: builder.mutation<Response<{ id: string }>, string>({
      query: (id) => ({
        url: `/organization/${id}`,
        method: 'DELETE',
      }),
    }),

    createOrganization: builder.mutation<
      Response<OrganizationModel>,
      AddOrganizationModel
    >({
      query: (body) => ({
        url: '/organization/',
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const {
  useLoginMutation,
  useForgotPasswordMutation,
  useConfirmForgotPasswordMutation,
  useUsersQuery,
  useCreateUserMutation,
  useDeleteUserMutation,

  useAllOrganizationsQuery,
  useDeleteOrganizationMutation,
  useCreateOrganizationMutation,
} = authApi
