const theme = {
  primary: 'var(--colors-primary)',
  text: 'var(--colors-text)',
  subText: 'var(--colors-sub-text)',
  link: 'var(--colors-link)',
  bg1: 'var(--colors-bg1)',
  bg2: 'var(--colors-bg2)',
  boxShadow: 'var(--box-shadow)',
}

export { theme }
