import { Form, Input, Modal, ModalProps, Select, notification } from 'antd'
import { AddUserModel, OrganizationModel } from 'models'
import { useAllOrganizationsQuery, useCreateUserMutation } from 'services/auth'
import { useEffect } from 'react'

interface Props extends ModalProps {
  onClose: (withRefresh: boolean) => void
  onSuccess: () => void
}
export default function CreateNewUser({ onClose, onSuccess, ...props }: Props) {
  const [form] = Form.useForm<AddUserModel>()

  const { data: organizationList } = useAllOrganizationsQuery({
    page: 1,
    size: 100,
  })

  const [createUser, { isLoading, data, error }] = useCreateUserMutation()

  useEffect(() => {
    if (data?.user_name) {
      notification.success({
        message: 'Success',
        description: `Successfully created user ${data?.user_name}`,
      })
      onClose(true)
      onSuccess()
    }
  }, [data, onClose, onSuccess])

  useEffect(() => {
    if (error) {
      notification.error({
        message: 'Error',
        description: (error as any)?.data?.errors?.detail,
      })
    }
  }, [error])

  const onFinish = ({ name, email, organization_id }: AddUserModel) => {
    createUser({
      name,
      email,
      organization_id,
    })
  }

  return (
    <Modal
      title="Create new user"
      centered
      okText="Create"
      onCancel={() => onClose(false)}
      okButtonProps={{ htmlType: 'submit', loading: isLoading }}
      onOk={form.submit}
      {...props}
    >
      <Form layout="vertical" onFinish={onFinish} form={form}>
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: 'Please input user name' }]}
        >
          <Input placeholder="User name..." />
        </Form.Item>

        <Form.Item
          name="email"
          label="Email"
          rules={[
            { required: true, message: 'Please input user email' },
            {
              type: 'email',
              message: 'The input is not valid E-mail!',
            },
          ]}
        >
          <Input placeholder="User email..." />
        </Form.Item>

        <Form.Item name="organization_id" label="Organization">
          <Select
            placeholder="Select organization..."
            options={organizationList?.data?.map((org: OrganizationModel) => ({
              value: org.id,
              label: org.name,
            }))}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}
